import React, { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { NightModeContext } from "../context/NightModeContext";

const Layout = () => {
  const { nightMode, setNightMode } = useContext(NightModeContext);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/onboard") {
      setNightMode(false);
    }
  }, [location.pathname, setNightMode]);
  return (
    <div
      className={`flex w-full h-screen overflow-hidden bg-[#${
        nightMode ? "2E1B5B" : "F4F6FB"
      }]`}
    >
      {console.log("location", location)}
      <div className="md:block sm:hidden hidden max-w-[20rem]">
        <Sidebar />
      </div>
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header />
        <main >
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
