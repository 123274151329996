import React, { useState } from "react";
import { TfiMenuAlt } from "react-icons/tfi";
import { FaChevronLeft, FaRegBell } from "react-icons/fa6";
import SurvTask from "../../../components/SurvTask";
import { FaChevronRight } from "react-icons/fa6";
// import { useNavigate } from "react-router-dom";
import { LuUser2 } from "react-icons/lu";
import { MdEdit, MdOutlineCake, MdOutlineFilterAlt } from "react-icons/md";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { BiRightArrow } from "react-icons/bi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const MobileHome = () => {
  const [hover, setHover] = useState(0);
  const [chosen, setChosen] = useState(0);
  const [text, setText] = useState("");
  const [saved, setSaved] = useState(null);
  const [history, setHistory] = useState(false);
  // const navigate = useNavigate();
  const [dropDown, setDropDown] = useState(false);

  const surv = {
    name: "survey",
    date: "2024/02/12",
    questionCount: 7,
    completedCount: 1,
  };

  const handleSave = () => {
    setSaved({
      feels: chosen,
      text: text,
    });
    setChosen(0);
    setText(0);
  };

  const handleEdit = () => {
    setText(saved.text);
    setChosen(saved.feels);
    setSaved(null);
  };

  const data = {
    labels: ["Дав", "Мяг", "Лха", "Пүр", "Ба", "Бя", "Ня"],
    datasets: [
      {
        label: "Emotion Levels",
        data: [1, 3, 2, 2, 1, 4, 5],
        borderColor: "#CECFD3",
        backgroundColor: "#CECFD3",
        pointBackgroundColor: "#5A6ACF",
        pointBorderColor: "#5A6ACF",
        borderWidth: 2,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        min: 1,
        max: 5,
        ticks: {
          stepSize: 1, // Ensure ticks are whole numbers
          display: false, // Hide the default numeric y-ticks
        },
        grid: {
          color: "#D9DCDC",
        },
        border: {
          dash: [8, 4],
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div>
      {!history ? (
        <div className="mt-6 w-full flex flex-col items-start justify-between">
          <div
            className={`bg-[#F4F6FB] px-4 py-4 rounded-xl w-full relative mb-6`}
          >
            {saved === null ? (
              <div className="flex items-center justify-center">
                <div>
                  {chosen === 0 && (
                    <p className="mb-2 text-center text-sm text-[#1E293B]">
                      Өнөөдөр хэр байна даа?
                    </p>
                  )}
                  <div className="flex items-center justify-center gap-2">
                    <button
                      onMouseEnter={() => {
                        setHover(1);
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                      onClick={() => {
                        setChosen(1);
                      }}
                    >
                      <img
                        className={`${hover === 1 ? "w-[40px]" : "w-[32px]"} ${
                          chosen === 1 ? "w-[40px]" : "w-[32px]"
                        }`}
                        src="/img/home/emoji1.gif"
                        alt="emoji"
                      />
                    </button>
                    <button
                      onMouseEnter={() => {
                        setHover(2);
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                      onClick={() => {
                        setChosen(2);
                      }}
                    >
                      <img
                        className={`${hover === 2 ? "w-[40px]" : "w-[32px]"} ${
                          chosen === 2 ? "w-[40px]" : "w-[32px]"
                        }`}
                        src="/img/home/emoji2.gif"
                        alt="emoji"
                      />
                    </button>
                    <button
                      onMouseEnter={() => {
                        setHover(3);
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                      onClick={() => {
                        setChosen(3);
                      }}
                    >
                      <img
                        className={`${hover === 3 ? "w-[40px]" : "w-[32px]"} ${
                          chosen === 3 ? "w-[40px]" : "w-[32px]"
                        }`}
                        src="/img/home/emoji3.gif"
                        alt="emoji"
                      />
                    </button>
                    <button
                      onMouseEnter={() => {
                        setHover(4);
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                      onClick={() => {
                        setChosen(4);
                      }}
                    >
                      <img
                        className={`${hover === 4 ? "w-[40px]" : "w-[32px]"} ${
                          chosen === 4 ? "w-[40px]" : "w-[32px]"
                        }`}
                        src="/img/home/emoji4.gif"
                        alt="emoji"
                      />
                    </button>
                    <button
                      onMouseEnter={() => {
                        setHover(5);
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                      onClick={() => {
                        setChosen(5);
                      }}
                    >
                      <img
                        className={`${hover === 5 ? "w-[40px]" : "w-[32px]"} ${
                          chosen === 5 ? "w-[40px]" : "w-[32px]"
                        }`}
                        src="/img/home/emoji5.gif"
                        alt="emoji"
                      />
                    </button>
                  </div>
                  {chosen !== 0 && (
                    <div>
                      <p className="text-center text-[##1E293B] text-xs">
                        Яагаад ийм мэдрэмж мэдэрч буйгаа тэмдэглэл болгон хөтлөх
                        үү? 😌
                      </p>
                      <div className="flex justify-center mt-2 ">
                        <textarea
                          onChange={(e) => {
                            setText(e.target.value);
                          }}
                          value={text}
                          className="text-xs w-full h-[90px] px-4 py-2 rounded-xl"
                          placeholder="Таны өгсөн хариулт зөвхөн танд л харагдахаар загварчлагдсан тул чөлөөтэй байгаарай!"
                        ></textarea>
                      </div>
                      {text !== "" && (
                        <div className="flex justify-end mt-4">
                          <button
                            onClick={handleSave}
                            className="text-[#fff] text-sm bg-[#324D72] px-3 py-1 rounded-lg "
                          >
                            хадгалах
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-center gap-4">
                <img
                  className="w-[40px]"
                  src={`/img/home/emoji${saved.feels}.gif`}
                  alt="emoji"
                />
                <div>
                  <p className="text-[#ABADB5] text-xs">2024/10/01</p>
                  <p className="text-[#6B7280] text-xs max-w-[300px] max-h-[200px] overflow-y-auto">
                    {saved.text}
                  </p>
                </div>
                <button onClick={handleEdit}>
                  <MdEdit className="w-[20px]" />
                </button>
              </div>
            )}
            {chosen === 0 && !history && (
              <div className="flex justify-end">
                <button
                  onClick={() => setHistory(true)}
                  className="text-[#324d72] text-sm mt-2 border-b border-[#324d72] "
                >
                  өмнөх түүх харах
                </button>
              </div>
            )}
          </div>
          <hr className="text-[#CEDAE9] my-4 h-[2px] w-full" />
          <div className="py-2 px-3 shadow-custom rounded-lg flex items-center justify-between w-full mb-4">
            <div className="flex items-center gap-2">
              <div>
                <img src="/img/home/streak.svg" alt="streak" />
              </div>
              <div>
                <p className="text-[#1E293B] text-sm font-semibold">
                  Challenge name
                </p>
                <p className="text-xs opacity-40">1 day streak!</p>
              </div>
            </div>
            <button className="py-1 px-3 border border-[#E5E6E8] rounded-lg flex items-center gap-1 text-[10px] text-[#1E293B] ">
              <BiRightArrow className="text-sm" />
              Үргэлжлүүлэх
            </button>
          </div>
          <div className="flex items-center justify-between w-full mb-4">
            <p className="text-[#1E293B] text-sm">Challenges</p>
            <button className="text-[#1E293B] text-[10px] ">
              Бүгдийг харах
            </button>
          </div>
          <div className="overflow-x-auto  flex items-center gap-4">
            <div className="w-[255px] h-[92px] flex items-center justify-center rounded-xl bg-[#E8EDF4]">
              <div className="flex items-center justify-between w-full px-6">
                <div>
                  <p className="text-[#3D4BEF] opacity-70 text-xs">
                    7 хоногийн
                  </p>
                  <p className="text-[#3D4BEF] text-sm font-semibold">
                    Шинэ Challenge
                  </p>
                  <p className="text-[#3D4BEF] opacity-70 text-xs">нэмэгдлээ</p>
                </div>
                <div>
                  <img src="/img/home/challenge1.svg" alt="challenge" />
                </div>
              </div>
            </div>
            <div className="w-[255px] h-[92px] flex items-center justify-center rounded-xl bg-[#FFE8E7]">
              <div className="flex items-center justify-between w-full px-6">
                <div>
                  <p className="text-[#FE8F8D] opacity-70 text-xs">
                    21 хоногийн
                  </p>
                  <p className="text-[#FE8F8D] text-sm font-semibold">
                    “Name” challenge
                  </p>
                  <p className="text-[#FE8F8D] opacity-70 text-xs">нэмэгдлээ</p>
                </div>
                <div>
                  <img src="/img/home/challenge2.svg" alt="challenge" />
                </div>
              </div>
            </div>
          </div>
          <hr className="text-[#CEDAE9] my-4 h-[2px] w-full" />
          <div className="flex items-center justify-between mb-4 w-full">
            <div>
              <p className="text-[#1E293B] text-sm font-semibold">
                Төрсөн өдрийн эзэд
              </p>
              <p className="text-[10px] text-[#1E293B]">
                Төрсөн өдрийн мэнд хүргэж баярлуулаарай!
              </p>
            </div>
            <div>
              <p className="text-[#1E293B] text-[10px]">2024/10/31</p>
            </div>
          </div>
          <div className="overflow-x-scroll  flex items-center gap-4 mb-4">
            <div
              style={{ backgroundImage: 'url("/img/home/bday.svg")' }}
              className="h-full bg-cover bg-center rounded-xl w-[320px]"
            >
              <div className="w-full bg-[#C754C0] bg-opacity-20 rounded-t-[20px] h-[60px] relative">
                <div className="bg-[#fff] h-[50p] w-[50px] flex items-center justify-center p-1 rounded-lg absolute left-6 -bottom-3">
                  <img src="/user2.svg" alt="user" />
                </div>
              </div>
              <div className="w-full bg-[#C850C0] bg-opacity-90 rounded-b-[20px] h-[117px]">
                <div className="flex items-center justify-between w-full pt-3 px-6">
                  <div>
                    <p className="text-[#fff] font-semibold">
                      Энхжин Отгонжаргал
                    </p>
                    <p className="text-[#fff] text-xs">Хэлтэс, албан тушаал</p>
                  </div>
                  <div>
                    <p className="text-[#fff] text-[20px] mb-0">20</p>
                    <p className="text-[#fff] text-xs">нас</p>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center w-full pt-3">
                  <div className="bg-[#fff] w-[30px] h-[30px] rounded-lg flex items-center justify-center">
                    <img src="/img/home/gift.svg" alt="gift" />
                  </div>
                  <p className="text-[#fff] text-[8px] text-center">
                    Бэлэг илгээх
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{ backgroundImage: 'url("/img/home/bday.svg")' }}
              className="h-full bg-cover bg-center rounded-xl w-[320px]"
            >
              <div className="w-full bg-[#FFCC70] bg-opacity-20 rounded-t-[20px] h-[60px] relative">
                <div className="bg-[#fff] h-[50p] w-[50px] flex items-center justify-center p-1 rounded-lg absolute left-6 -bottom-3">
                  <img src="/user2.svg" alt="user" />
                </div>
              </div>
              <div className="w-full bg-[#FFC45A] bg-opacity-90 rounded-b-[20px] h-[117px]">
                <div className="flex items-center justify-between w-full pt-3 px-6">
                  <div>
                    <p className="text-[#fff] font-semibold">
                      Энхжин Отгонжаргал
                    </p>
                    <p className="text-[#fff] text-xs">Хэлтэс, албан тушаал</p>
                  </div>
                  <div>
                    <p className="text-[#fff] text-[20px] mb-0">20</p>
                    <p className="text-[#fff] text-xs">нас</p>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center w-full pt-3">
                  <div className="bg-[#fff] w-[30px] h-[30px] rounded-lg flex items-center justify-center">
                    <img src="/img/home/gift.svg" alt="gift" />
                  </div>
                  <p className="text-[#fff] text-[8px] text-center">
                    Бэлэг илгээх
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className="mb-4 text-[#293951] text-xs ">
            Та ч бас төрсөн өдрөө оруулна уу.
          </p>
          <div className="w-full relative mb-4">
            <MdOutlineCake className="absolute left-2 top-1.5 text-[#293951] text-sm" />
            <input
              className="w-full text-xs rounded-md border border-[#CECFD3] ps-6 pe-3 py-1 text-[#293951]"
              type="date"
            />
          </div>
          <div className="w-full bg-[#F4F6FB] rounded-lg mb-4">
            <div
              onClick={() => {
                setDropDown(!dropDown);
              }}
              className="flex items-center justify-between py-2 px-3 cursor-pointer"
            >
              <p className="text-xs text-[#1E293B]">
                {dropDown
                  ? "Тэдгээрийг баярлуулахдаа урьдчилан бэлтгээрэй!"
                  : "Удахгүй болох төрсөн өдрийн эздүүд харах"}
              </p>
              <button
                className={`text-[#1E293B] transition-transform duration-300 ${
                  dropDown ? "rotate-180" : "rotate-0"
                }`}
              >
                <IoIosArrowDown />
              </button>
            </div>
            <div
              className={`overflow-hidden transition-all duration-500 ${
                dropDown
                  ? "max-h-[300px] mt-2 opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {dropDown && (
                <div className="animate-fade-in">
                  <div className="flex items-center justify-between w-full py-2 px-3 rounded-xl mb-4">
                    <div className="flex items-center gap-2">
                      <div className="w-[40px] h-[40px] p-[3px] rounded-lg bg-[#fff] flex items-center justify-center">
                        <img
                          className="object-fill w-full h-full rounded-full"
                          src="/user1.svg"
                          alt="user"
                        />
                      </div>

                      <div>
                        <p className="text-[#324D72] text-xs font-semibold">
                          Энхжин Отгонжаргал
                        </p>
                        <p className="text-[#324D72] text-[10px]">
                          IT, UI/UX designer
                        </p>

                        <p className="text-[#324D72] text-[10px]">
                          <span className="text-[15px]">16</span>.November
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="text-center">
                        <p className="text-[#324D72] text-[15px]">22</p>
                        <p className="text-[#324D72] text-[10px] opacity-80">
                          өдөр үлдсэн
                        </p>
                      </div>
                      <button className="text-[#324D72]">
                        <img src="/icon/arrow-right.svg" alt="arrow" />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <hr className="text-[#CEDAE9] my-4 h-[2px] w-full" />
          <p className="text-[#1E293B] text-sm mb-2">
            Тантай адил сонирхолтой хүмүүс
          </p>
          <div className="w-full flex justify-end mb-4">
            <button className="flex items-center gap-2 text-[#324D72] text-xs border border-[#CEDAE9] rounded-lg px-2 py-1">
              шүүлтүүр{" "}
              <span className="text-base">
                <MdOutlineFilterAlt />
              </span>
            </button>
          </div>
          <div className="shadow-custom bg-[#fff] py-3 px-5 rounded-xl mb-4">
            <div className="flex items-center gap-4 mb-3">
              <div className="flex rounded-full border w-[40px] h-[40px] items-center justify-center">
                <img
                  className=" object-fill w-full h-full"
                  src="/user.svg"
                  alt="user"
                />
              </div>
              <div>
                <p className="text-[#1E293B] text-sm">Овог Нэр</p>
                <p className="text-[#64748B] text-sm">Хэлтэс, албан тушаал</p>
              </div>
            </div>
            <div>
              <p className="text-[#64748B] text-xs mb-1">Нийцэж буй</p>
              <div className="flex flex-wrap items-center gap-2 mb-3">
                <div className="px-2 py-1 bg-[#7EEFB2] bg-opacity-20 rounded-lg">
                  <p className="text-[#3D608C] text-xs">🎧 Music</p>
                </div>
                <div className="px-2 py-1 bg-[#7EEFB2] bg-opacity-20 rounded-lg">
                  <p className="text-[#3D608C] text-xs">🌴 Travel</p>
                </div>
              </div>
              <p className="text-[#64748B] text-xs mb-1">Бусад сонирхол</p>
              <div className="flex flex-wrap items-center gap-2">
                <div className="px-2 py-1 bg-[#F9F9F9] rounded-lg">
                  <p className="text-[#3D608C] text-xs">⚽ Soccer</p>
                </div>
                <div className="px-2 py-1 bg-[#F9F9F9] rounded-lg">
                  <p className="text-[#3D608C] text-xs">🎮 Gaming</p>
                </div>
                <div className="px-2 py-1 bg-[#F9F9F9] rounded-lg">
                  <p className="text-[#3D608C] text-xs">🏐 Volleyball</p>
                </div>
                <div className="px-2 py-1 bg-[#F9F9F9] rounded-lg">
                  <p className="text-[#3D608C] text-xs">☕️ Coffee</p>
                </div>
              </div>
            </div>
          </div>
          {/* <hr className="text-[#CEDAE9] h-[2px] w-full my-6" />
          <div className="">
            <p className="text-[#293951] font-semibold mb-10 flex items-center gap-2">
              <FaRegBell />
              Сүүлийн үеийн шинэчлэлүүд
            </p>

            <div className="w-full p-4 bg-white shadow-custom rounded-xl mb-4">
              <div className="flex items-center justify-between">
                <p className="text-[#64748B] text-xs py-0.5">2024/12/10</p>

                <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                  <p className="text-[#FF9D72] font-semibold text-sm">new</p>
                </div>
              </div>
              <div>
                <p className="text-xs text-[#222] py-2">
                  Амар та цаашид хэрэгжүүлж эхлэх зүйл -г хийж эхэлвэл гарах үр
                  дүн -д хүрч чадна. Би таныг чадна гэдэгт итгэлтэй байна 🤗
                </p>
              </div>
              <div className="flex items-center gap-2 mt-1">
                <div className="p-1 border border-[#000] bg-white rounded-full">
                  <LuUser2 className="text-xl text-[#000]" />
                </div>
                <div>
                  <p className="text-[#1E293B] text-xs">Овог Нэр</p>
                  <p className="text-[#64748B] text-xs">Хэлтэс албан тушаал</p>
                </div>
              </div>
            </div>
            <div className="w-full p-4 bg-white shadow-custom rounded-xl mb-4">
              <div className="flex items-center justify-between">
                <p className="text-[#64748B] text-xs py-0.5">2024/12/10</p>

                <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                  <p className="text-[#FF9D72] font-semibold text-sm">new</p>
                </div>
              </div>
              <div>
                <p className="text-xs text-[#222] py-2">
                  Амар та цаашид хэрэгжүүлж эхлэх зүйл -г хийж эхэлвэл гарах үр
                  дүн -д хүрч чадна. Би таныг чадна гэдэгт итгэлтэй байна 🤗
                </p>
              </div>
              <div className="flex items-center gap-2 mt-1">
                <img src="/img/incognito.svg" alt="incognito" />

                <div>
                  <p className="text-[#1E293B] text-xs">Нэрээ нууцалсан</p>
                </div>
              </div>
            </div>
          </div>
          <hr className="text-[#CEDAE9] h-[2px] w-full my-6" />
          <div className="">
            <p className="text-[#293951] font-semibold mb-10 flex items-center gap-2">
              <TfiMenuAlt />
              Гүйцэтгэх шаардлагатай зүйлс
            </p>
          </div> */}
        </div>
      ) : (
        <div>
          <button
            onClick={() => {
              setHistory(false);
            }}
            className="text-[#1E293B] text-xs flex items-center gap-2 mb-4"
          >
            <FaChevronLeft /> Буцах
          </button>
          <div className="bg-[#F4F6FB] px-4 py-4 mb-6 rounded-md">
            <div className="flex justify-center">
              {saved === null ? (
                ""
              ) : (
                <img
                  className="w-[35px]"
                  src={`/img/home/emoji${saved.feels}.gif`}
                  alt="emoji"
                />
              )}
            </div>
            <p className="text-xs text-[#ABADB5]">2024/10/01</p>
            <p className="text-xs text-[#6B7280] w-full max-h-[100px] overflow-y-auto">
              {saved?.text}
            </p>
          </div>
          <div style={{ width: "full", height: "auto" }}>
            <Line data={data} options={options} />
          </div>
          <div className="flex justify-center items-center mt-4 gap-2">
            <button className="text-xs">
              <FaChevronLeft />
            </button>
            <p className="text-[#777985] text-xs flex items-center gap-2">
              10 дугаар сар, 1-р 7 хоног
            </p>
            <button className="text-xs">
              <FaChevronRight />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileHome;
