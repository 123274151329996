import React from "react";
import { IoIosArrowForward } from "react-icons/io";

const Tasks = () => {
  return (
    <div>
      <div className="w-full h-[192px] shadow-custom rounded-lg mb-4">
        <div
          style={{ backgroundImage: "url(/img/challenge/macbook.svg)" }}
          className="h-[117px] w-full rounded-t-lg py-2 px-3 object-fill"
        >
          <div className="flex items-center gap-2">
            <p className="text-[#fff] text-[10px]">Logo here</p>
            <p className="text-[#fff] text-[10px] opacity-80">x</p>
            <p className="text-[#fff] text-[10px]">Oneplace.hr</p>
          </div>
          <div className="mt-8">
            <p className="text-[20px] text-[#fff] font-semibold">Macbook Pro</p>
            <p className="text-xs text-[#fff]">хожих шинэ challenge</p>
          </div>
        </div>
        <div className="px-3 py-2">
          <div className="flex items-start justify-between">
            <div>
              <p className="text-[#324D72] text-sm font-semibold">
                Challenge нэр
              </p>
              <div className="flex items-center gap-1 my-1">
                <img src="/icon/star.svg" alt="point" />
                <p className="text-xs text-[#324D72]">1000 pts</p>
              </div>
            </div>
            <p className="text-xs text-[#324D72]">Тохирлын эрх: 3</p>
          </div>
          <div className="flex items-start justify-between ">
            <p className="text-[#575763] text-[10px]">
              2024/12/21 хүртэл үргэлжилнэ
            </p>
            <button className="text-[#324d72] text-xs flex items-center gap-1">
              Дэлгэрэнгүй
              <IoIosArrowForward />
            </button>
          </div>
        </div>
      </div>
      <div className="w-full h-[192px] shadow-custom rounded-lg mb-4">
        <div
          style={{ backgroundImage: "url(/img/challenge/macbook.svg)" }}
          className="h-[117px] w-full rounded-t-lg py-2 px-3 object-fill"
        >
          <div className="flex items-center gap-2">
            <p className="text-[#fff] text-[10px]">Logo here</p>
            <p className="text-[#fff] text-[10px] opacity-80">x</p>
            <p className="text-[#fff] text-[10px]">Oneplace.hr</p>
          </div>
          <div className="mt-8">
            <p className="text-[20px] text-[#fff] font-semibold">Macbook Pro</p>
            <p className="text-xs text-[#fff]">хожих шинэ challenge</p>
          </div>
        </div>
        <div className="px-3 py-2">
          <div className="flex items-start justify-between">
            <div>
              <p className="text-[#324D72] text-sm font-semibold">
                Challenge нэр
              </p>
              <div className="flex items-center gap-1 my-1">
                <img src="/icon/star.svg" alt="point" />
                <p className="text-xs text-[#324D72]">1000 pts</p>
              </div>
            </div>
            <p className="text-xs text-[#324D72]">Тохирлын эрх: 3</p>
          </div>
          <div className="flex items-start justify-between ">
            <p className="text-[#575763] text-[10px]">
              2024/12/21 хүртэл үргэлжилнэ
            </p>
            <button className="text-[#324d72] text-xs flex items-center gap-1">
              Дэлгэрэнгүй
              <IoIosArrowForward />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tasks;
