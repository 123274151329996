import React from "react";

const TestEnding = () => {
  const required = 60;
  const goal = 100;
  const point = 40;
  const isPassed = point >= required;

  return (
    <div className="min-h-screen w-full bg-[#F3F4F8] flex justify-center pt-11">
      <div>
        <div className="w-[320px] sm:w-[514px] rounded-lg bg-[#fff] border-t-4 border-[#324D72] px-8 py-4 mb-4">
          <div>
            <p className="text-[#1E293B] text-[20px] ">Onboarding test</p>
          </div>
          <hr className="bg-[#E5E6E8] h-[2px] my-1" />
          <div>
            <div className="flex items-center gap-2">
              <p className="text-[#324D72]">Тест амжилттай дууслаа.</p>
            </div>
            <p className="text-[#1E293B] text-lg">
              {point} <span className="text-[#666874] text-sm ">/ {goal}</span>
            </p>
            <p className="text-[#324d72] text-xs sm:text-sm flex items-center gap-1">
              {isPassed
                ? "Та тестэд тэнцсэн байна 🎉"
                : "Та хангалттай оноо авч чадаагүй байна."}
              {!isPassed && (
                <img
                  className="w-[20px]"
                  src="/img/home/emoji5.gif"
                  alt="emoji"
                />
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestEnding;
