import React, { useContext, useEffect, useState } from "react";

import { TitleContext } from "../context/TitleContext";
import { SidebarContext } from "../context/SidebarContext";

import MobileHome from "../components/home/mobile/MobileHome";
import DesktopHome from "../components/home/desktop/DesktopHome";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal";

const Home = () => {
  const { setTitle } = useContext(TitleContext);
  const { setSidebar } = useContext(SidebarContext);
  const [popUp, setPopUp] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTitle("Home Page Title");
  }, [setTitle]);

  useEffect(() => {
    navigate("/feed-forward");
  }, [navigate]);

  useEffect(() => {
    setSidebar("open");
  }, [setSidebar]);

  return (
    <div className="mt-11 w-10/12 m-auto">
      {popUp && <Modal modal={popUp} setModal={setPopUp} />}
      <div className="sm:hidden hidden md:block">
        <DesktopHome />
      </div>
      <div className="sm:block block md:hidden">
        <MobileHome />
      </div>
    </div>
  );
};

export default Home;
