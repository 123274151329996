import React, { useState } from "react";
import "../style/custom-checkbox.css";
import { useNavigate } from "react-router-dom";

const Test = () => {
  const navigate = useNavigate();
  const question = [
    {
      question: "What is Lorem Ipsum?",
      points: 30,
      type: "checkbox",
      choices: ["Simple text", "Common belief", "Passage", "Other"],
    },
    {
      question: "What is Lorem Ipsum?",
      points: 30,
      type: "multi",
      choices: ["Simple text", "Common belief", "Passage", "Other"],
    },
    {
      question: "What is Lorem Ipsum?",
      points: 30,
      type: "multi",
      choices: ["Simple text", "Common belief", "Passage", "Passage", "Other"],
    },
    {
      question: "What is Lorem Ipsum?",
      points: 30,
      type: "consider",
      choices: ["Simple text", "Common belief", "Passage", "Passage", "Other"],
    },
  ];

  const questions = [
    { id: 1, text: "Асуулт 1", score: 5 },
    { id: 2, text: "Асуулт 2", score: 5 },
    { id: 3, text: "Асуулт 3", score: 5 },
  ];

  const answers = ["Хариулт 1", "Хариулт 2", "Хариулт 3"];

  const [selectedChoices, setSelectedChoices] = useState(
    question.map(() => [])
  );

  const handleCheck = (questionIndex, choiceIndex, type) => {
    setSelectedChoices((prev) => {
      const updatedChoices = [...prev];
      if (type === "checkbox") {
        // Checkbox type allows selecting only one option
        updatedChoices[questionIndex] = [choiceIndex];
      } else if (type === "multi") {
        // Multi type allows toggling options
        const currentSelection = updatedChoices[questionIndex];
        if (currentSelection.includes(choiceIndex)) {
          updatedChoices[questionIndex] = currentSelection.filter(
            (index) => index !== choiceIndex
          );
        } else {
          updatedChoices[questionIndex] = [...currentSelection, choiceIndex];
        }
      }
      return updatedChoices;
    });
  };

  const allQuestionsAnswered = selectedChoices.every(
    (choices) => choices.length > 0
  );

  return (
    <div className="min-h-screen w-full bg-[#F3F4F8] flex justify-center pt-11 px-4 sm:px-8 lg:px-16">
      <div className="max-w-xl w-full">
        {/* Header Section */}
        <div className="rounded-lg bg-white border-t-4 border-[#324D72] px-6 sm:px-8 py-4 mb-4">
          <p className="text-[#1E293B] text-lg sm:text-xl font-semibold">
            Onboarding Test
          </p>
          <hr className="bg-gray-300 h-[2px] my-2" />
          <p className="text-xs sm:text-sm text-[#1E293B]">
            Дараах асуултуудад хариулан мэдлэгээ баталгаажуулж Onboarding
            даалгавраа илгээгээрэй.
          </p>
        </div>

        {/* Progress Section */}
        <div className="rounded-lg bg-white px-6 sm:px-8 py-4 mb-4">
          <div className="flex items-center justify-between mt-2">
            <p className="text-gray-700 text-xs sm:text-sm">0</p>
            <p className="text-gray-700 text-xs sm:text-sm">100</p>
          </div>
          <div className="relative">
            <div className="absolute left-1/2 -top-7 transform -translate-x-1/2">
              <p className="text-xs sm:text-sm text-center text-gray-800">60</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="8"
                viewBox="0 0 13 8"
                fill="none"
              >
                <path d="M6.5 8L0.00480938 0.5H12.9952L6.5 8Z" fill="#00D97D" />
              </svg>
            </div>
            <div className="w-full h-2 bg-green-400 bg-opacity-20 rounded-lg flex items-center">
              <div className="w-[60%] h-2 bg-green-400 rounded-lg"></div>
            </div>
          </div>
          <p className="text-gray-700 text-xs sm:text-sm mt-2">
            60 онооноос дээш тохиолдолд тэнцсэнд тооцно.
          </p>
        </div>

        {/* Questions Section */}
        {question.map((items, questionIndex) => (
          <div
            key={questionIndex}
            className="rounded-lg bg-white px-6 sm:px-8 py-4 mb-4"
          >
            <div className="flex items-center justify-between">
              <p className="text-gray-800 text-sm sm:text-base">
                {items.question}
              </p>
              <p className="text-gray-600 text-xs sm:text-sm">
                {items.points} оноо
              </p>
            </div>
            <hr className="bg-gray-300 h-[2px] my-2" />
            <div>
              {items.choices.map((choice, choiceIndex) => (
                <div key={choiceIndex} className="flex items-center gap-2 mb-2">
                  <div className="checkbox-container ">
                    <input
                      type="checkbox"
                      id={`custom-checkbox-${questionIndex}-${choiceIndex}`}
                      checked={selectedChoices[questionIndex].includes(
                        choiceIndex
                      )}
                      onChange={() =>
                        handleCheck(questionIndex, choiceIndex, items.type)
                      }
                      className="cursor-pointer w-4 h-4 border-gray-300 rounded text-[#324D72] focus:ring-[#324D72]"
                    />
                    <label
                      htmlFor={`custom-checkbox-${questionIndex}-${choiceIndex}`}
                      className={`hidden ${
                        items.type === "multi" ? "rounded-sm" : "rounded-full"
                      }`}
                    ></label>
                  </div>
                  <p className="text-xs sm:text-sm text-gray-800">{choice}</p>
                </div>
              ))}
            </div>
          </div>
        ))}

        <div className="rounded-lg bg-white px-6 sm-px-8 py-4 mb-4">
          {/* Header */}
          <div className="flex justify-between items-center pb-4 border-b border-gray-200">
            <h2 className="text-sm text-[#1E293B]">What is Lorem Ipsum?</h2>
            <p className="text-sm text-[#575763]">30 оноо</p>
          </div>

          {/* Table */}
          <div className="mt-4">
            <div className="overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr>
                    <th className="text-left text-gray-500 font-normal py-2">
                      {" "}
                    </th>
                    {answers.map((answer, index) => (
                      <th
                        key={index}
                        className="text-center text-xs text-[#1E293B] py-2"
                      >
                        {answer}
                      </th>
                    ))}
                    <th className="text-center text-xs text-[#1E293B] py-2">
                      Оноо
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {questions.map((question) => (
                    <tr key={question.id} className="border-t bg-[#F4F6FB]">
                      <td className="text-[#1E293B] text-xs ps-3 py-2">
                        {question.text}
                      </td>
                      {answers.map((_, index) => (
                        <td key={index} className="text-center py-2">
                          <input
                            type="radio"
                            name={`question-${question.id}`}
                            className="h-4 w-4  focus:ring-[#324d72] bg-none  border-[#CECFD3] "
                          />
                        </td>
                      ))}
                      <td className="text-center py-2 text-xs text-[#424348]">
                        {question.score} оноо
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end mb-6">
          <button
            className={`px-4 py-2 text-white text-sm sm:text-base rounded-lg transition ${
              allQuestionsAnswered
                ? "bg-[#324D72] hover:bg-[#273a59]"
                : "bg-gray-300 cursor-not-allowed"
            }`}
            disabled={!allQuestionsAnswered}
            onClick={() => navigate("ending")}
          >
            Илгээх
          </button>
        </div>
      </div>
    </div>
  );
};

export default Test;
