import React from "react";

const Leaderboard = () => {
  return (
    <div>
      <div className="shadow-custom bg-white flex items-center justify-between py-4 px-6 rounded-xl mb-6">
        <div className="mt-14 flex flex-col items-center justify-center">
          <div className="relative w-[70px] h-[70px] border-2 border-[#5A6ACF] rounded-full">
            <img
              className="w-full h-full object-fill"
              src="/user1.svg"
              alt="img"
            />
            <div className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 h-[28px] w-[28px] rounded-full bg-[#5A6ACF] flex items-center justify-center">
              <p className="text-[#fff] font-bold">2</p>
            </div>
          </div>
          <p className="text-center text-[#1E293B] font-semibold text-xs mt-6">
            Ariunaa Batjargal
          </p>
          <p className="text-xs text-[#1E293B] flex items-center gap-1 ">
            <span>
              <img src="/icon/star.svg" alt="star" />
            </span>
            200 pts
          </p>
        </div>
        <div className="mt-2 flex flex-col items-center justify-center">
          <div className="relative w-[70px] h-[70px] border-2 border-[#5A6ACF] rounded-full">
            <img
              className="-top-5 absolute left-1/2 transform -translate-x-1/2"
              src="/img/challenge/crown.svg"
              alt="crown"
            />
            <img
              className="w-full h-full object-fill"
              src="/user.svg"
              alt="img"
            />
            <div className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 h-[28px] w-[28px] rounded-full bg-[#5A6ACF] flex items-center justify-center">
              <p className="text-[#fff] font-bold">1</p>
            </div>
          </div>
          <p className="text-center text-[#1E293B] font-semibold text-xs mt-6">
            Nomin Bat
          </p>
          <p className="text-xs text-[#1E293B] flex items-center gap-1 ">
            <span>
              <img src="/icon/star.svg" alt="star" />
            </span>
            200 pts
          </p>
        </div>
        <div className="mt-14 flex flex-col items-center justify-center">
          <div className="relative w-[70px] h-[70px] border-2 border-[#5A6ACF] rounded-full">
            <img
              className="w-full h-full object-fill"
              src="/user2.svg"
              alt="img"
            />
            <div className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 h-[28px] w-[28px] rounded-full bg-[#5A6ACF] flex items-center justify-center">
              <p className="text-[#fff] font-bold">3</p>
            </div>
          </div>
          <p className="text-center text-[#1E293B] font-semibold text-xs mt-6">
            Enkhjin Otgonjargal
          </p>
          <p className="text-xs text-[#1E293B] flex items-center gap-1 ">
            <span>
              <img src="/icon/star.svg" alt="star" />
            </span>
            200 pts
          </p>
        </div>
      </div>
      <div>
        <div className="bg-white rounded-xl px-5 py-2 flex items-center justify-between shadow-custom mb-3">
          <div className="flex items-center gap-2">
            <p className="text-sm text-[#4F4F4F] font-semibold">4</p>
            <div className="flex items-center gap-1">
              <div className="h-[30px] w-[30px] rounded-full">
                <img
                  className="w-full h-full object-fill"
                  src="/user3.svg"
                  alt="user"
                />
              </div>
              <div>
                {" "}
                <p className="text-[#4F4F4F] text-sm font-semibold">
                  Nomin Bat
                </p>
                <p className="text-xs text-[#4F4F4F]">@MCSI</p>
              </div>
            </div>
          </div>
          <p className="text-xs text-[#4F4F4F]">136 pts</p>
        </div>
        <div className="bg-white rounded-xl px-5 py-2 flex items-center justify-between shadow-custom mb-3">
          <div className="flex items-center gap-2">
            <p className="text-sm text-[#4F4F4F] font-semibold">5</p>
            <div className="flex items-center gap-1">
              <div className="h-[30px] w-[30px] rounded-full">
                <img
                  className="w-full h-full object-fill"
                  src="/user3.svg"
                  alt="user"
                />
              </div>
              <div>
                {" "}
                <p className="text-[#4F4F4F] text-sm font-semibold">
                  Nomin Bat
                </p>
                <p className="text-xs text-[#4F4F4F]">@MCSI</p>
              </div>
            </div>
          </div>
          <p className="text-xs text-[#4F4F4F]">136 pts</p>
        </div>
        <div className="bg-[#324D72] rounded-xl px-5 py-2 flex items-center justify-between shadow-custom mb-3">
          <div className="flex items-center gap-2">
            <p className="text-sm text-[#fff] font-semibold">6</p>
            <div className="flex items-center gap-1">
              <div className="h-[30px] w-[30px] rounded-full">
                <img
                  className="w-full h-full object-fill"
                  src="/user3.svg"
                  alt="user"
                />
              </div>
              <div>
                {" "}
                <p className="text-[#fff] text-sm font-semibold">Та</p>
              </div>
            </div>
          </div>
          <p className="text-xs text-[#fff]">136 pts</p>
        </div>
        <div className="bg-white rounded-xl px-5 py-2 flex items-center justify-between shadow-custom mb-3">
          <div className="flex items-center gap-2">
            <p className="text-sm text-[#4F4F4F] font-semibold">7</p>
            <div className="flex items-center gap-1">
              <div className="h-[30px] w-[30px] rounded-full">
                <img
                  className="w-full h-full object-fill"
                  src="/user3.svg"
                  alt="user"
                />
              </div>
              <div>
                {" "}
                <p className="text-[#4F4F4F] text-sm font-semibold">
                  Nomin Bat
                </p>
                <p className="text-xs text-[#4F4F4F]">@MCSI</p>
              </div>
            </div>
          </div>
          <p className="text-xs text-[#4F4F4F]">136 pts</p>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
