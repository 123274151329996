import React, { useState } from "react";
import Tasks from "./Challenges/Tasks";
import Leaderboard from "./Challenges/Leaderboard";
import PrizePool from "./Challenges/PrizePool";
import { useNavigate } from "react-router-dom";

const Challenges = () => {
  const [page, setPage] = useState("tasks");
  const navigate = useNavigate();

  const renderActiveTable = () => {
    switch (page) {
      case "tasks":
        return <Tasks />;
      case "leaderboard":
        return <Leaderboard />;
      case "prize":
        return <PrizePool />;
      default:
        return null;
    }
  };

  const handlePageChange = (table) => {
    setPage(table);
    navigate(`/challenges/${table}`);
  };

  return (
    <div className="w-10/12 m-auto pt-4">
      <div className="w-full p-1 bg-[#F9F9F9] border border-[#E5E6E8] rounded-md flex itmes-center justify-between mb-4">
        <button
          className={`w-[33%] rounded-sm text-xs ${
            page === "tasks"
              ? "text-[#fff] bg-[#324d72] font-semibold"
              : "text-[#324d72] bg-none"
          }`}
          onClick={() => {
            handlePageChange("tasks");
          }}
        >
          Challenges
        </button>
        <button
          className={`w-[33%] rounded-sm text-xs ${
            page === "leaderboard"
              ? "text-[#fff] bg-[#324d72] font-semibold"
              : "text-[#324d72] bg-none"
          }`}
          onClick={() => {
            handlePageChange("leaderboard");
          }}
        >
          Leaderboard
        </button>
        <button
          className={`w-[33%] rounded-sm text-xs ${
            page === "prize"
              ? "text-[#fff] bg-[#324d72] font-semibold"
              : "text-[#324d72] bg-none"
          }`}
          onClick={() => {
            handlePageChange("prize");
          }}
        >
          Prizes
        </button>
      </div>
      <div>{renderActiveTable()}</div>
    </div>
  );
};

export default Challenges;
